// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: currentuser.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import {
  Filter_OrganizationPermissions,
  Filter_SupportPermissions,
  Filter_UserPermissions,
  Pagination,
  Pagination_Result,
  Timestamp,
} from "./base";
import { Organization_Contact } from "./organization";
import { RoleSelection } from "./role";

export const protobufPackage = "hiber.user";

/** Your personal data. */
export interface CurrentUser {
  id: string;
  /**
   * Email address of the current user.
   * Only set when you have permission to read the user data (i.e. a token without that permission would not see this)
   */
  email?:
    | string
    | undefined;
  /**
   * Name of the current user.
   * Only set when you have permission to read the user data (i.e. a token without that permission would not see this)
   */
  name?:
    | string
    | undefined;
  /** The organizations that this user has access to. */
  organizations: string[];
  /** The default organization for this user, if any. */
  defaultOrganization?:
    | string
    | undefined;
  /** Open access requests. */
  requestedOrganizations: string[];
  /**
   * The current organization for this user.
   * If this is a user, this equals the default_organization, if any.
   * If this is a token, it's the token's organization.
   */
  currentOrganization?:
    | string
    | undefined;
  /** Permissions for the current organization. */
  currentOrganizationPermissions?:
    | Filter_OrganizationPermissions
    | undefined;
  /** Permissions for the user. If this is a token, the user permissions may be limited or omitted. */
  userPermissions?:
    | Filter_UserPermissions
    | undefined;
  /**
   * Permissions for customer support.
   * Used for features typically reserved for customer support, or that behave differently
   * when used by a customer support operator.
   */
  supportPermissions?:
    | Filter_SupportPermissions
    | undefined;
  /** Roles for the current organization. */
  roles: string[];
  missionControlSettings: string;
  /** Whether the user accepted the terms and conditions. */
  acceptedTac: boolean;
  userHash: string;
  /** Whether a password reset is recommended for this user. */
  passwordResetRecommended: boolean;
  /** Why a password reset is recommended for this user. */
  passwordResetRecommendedReason?:
    | string
    | undefined;
  /** Indicates that there is an active role override for this user. */
  activeRoleOverride: boolean;
}

/** Get your personal data. */
export interface CurrentUserRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?: string | undefined;
}

/**
 * Request access to an organization by name, if it exists.
 * You request will be saved and the organization owner notified.
 * Organization admins can approve or reject your request.
 */
export interface RequestAccessRequest {
  organization: string;
}

export interface RequestAccessRequest_Response {
}

/** Cancel a previously made access request. */
export interface CancelAccessRequestRequest {
  organization: string;
}

export interface CancelAccessRequestRequest_Response {
}

/** List all invitations from organizations. */
export interface ListOrganizationInvitesRequest {
  search?: string | undefined;
}

export interface ListOrganizationInvitesRequest_Invite {
  organization: string;
  displayName: string;
  invitedAt: Timestamp | undefined;
}

export interface ListOrganizationInvitesRequest_Response {
  organizations: ListOrganizationInvitesRequest_Invite[];
}

/** Accept an invitation to an organization. */
export interface AcceptOrganizationInviteRequest {
  organization: string;
  /** Set to true to mark the organization as your default organization. */
  defaultOrganization?: boolean | undefined;
}

export interface AcceptOrganizationInviteRequest_Response {
}

/**
 * Delete yourself.
 * Removes all login information and personal data, except for you email address for auditing purposes.
 */
export interface DeleteCurrentUserRequest {
}

export interface DeleteCurrentUserRequest_Response {
}

/**
 * Set the default organization to use when it is not specified in the call.
 * Note: this can be a child organization of one of the owned organizations.
 */
export interface UpdateDefaultOrganizationRequest {
  organization: string;
}

export interface UpdateDefaultOrganizationRequest_Response {
  defaultOrganization: string;
}

/** Update mission control settings, which are in a json format. */
export interface UpdateMissionControlSettingsRequest {
  update: string;
}

export interface UpdateMissionControlSettingsRequest_Response {
  missionControlSettings: string;
}

/** Accept the Hiber terms and conditions. */
export interface AcceptTermsAndConditionsRequest {
  acceptTac: boolean;
}

export interface AcceptTermsAndConditionsRequest_Response {
}

/** Log that the user has reset their password. */
export interface LogPasswordUpdatedRequest {
}

export interface LogPasswordUpdatedRequest_Response {
}

/** List all organizations that can be impersonated. */
export interface AccessibleOrganizationsRequest {
  /** Search accessible organizations by name. */
  search?:
    | string
    | undefined;
  /** Only list organizations of which you are a member (exclude organizations that you can only impersonate). */
  memberOnly?:
    | boolean
    | undefined;
  /** Only list your default organization. */
  defaultOnly?: boolean | undefined;
  pagination?: Pagination | undefined;
}

export interface AccessibleOrganizationsRequest_AccessibleOrganization {
  /** Organization identifier, e.g. "my-organization" */
  organization: string;
  /** Organization name, e.g. "My Organization" */
  displayName: string;
  /** The contact person for this organization */
  contact:
    | Organization_Contact
    | undefined;
  /** If true, you are a member of this organization (= you are directly linked to this organization) */
  member: boolean;
  /** If true, this is the organization that you use by default. */
  defaultOrganization: boolean;
}

export interface AccessibleOrganizationsRequest_Response {
  /** Details for the organizations that you can access. */
  organizations: AccessibleOrganizationsRequest_AccessibleOrganization[];
  pagination: Pagination_Result | undefined;
}

export interface OverrideRoles {
}

export interface OverrideRoles_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the roles to list. Optional, when omitted or empty everything is included. */
  selection?: RoleSelection | undefined;
}

export interface OverrideRoles_Response {
  currentUser: CurrentUser | undefined;
  request: OverrideRoles_Request | undefined;
}

export interface OverrideRoles_Restore {
}

export interface OverrideRoles_Restore_Request {
  /**
   * Optionally, specify organization(s) with an active override that you wish to remove.
   * If no organizations are provided, all overrides will be removed.
   */
  organization: string[];
}

export interface OverrideRoles_Restore_Response {
  currentUser: CurrentUser | undefined;
  request: OverrideRoles_Restore_Request | undefined;
}

function createBaseCurrentUser(): CurrentUser {
  return {
    id: "",
    email: undefined,
    name: undefined,
    organizations: [],
    defaultOrganization: undefined,
    requestedOrganizations: [],
    currentOrganization: undefined,
    currentOrganizationPermissions: undefined,
    userPermissions: undefined,
    supportPermissions: undefined,
    roles: [],
    missionControlSettings: "",
    acceptedTac: false,
    userHash: "",
    passwordResetRecommended: false,
    passwordResetRecommendedReason: undefined,
    activeRoleOverride: false,
  };
}

export const CurrentUser = {
  encode(message: CurrentUser, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.email !== undefined) {
      writer.uint32(18).string(message.email);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.organizations) {
      writer.uint32(34).string(v!);
    }
    if (message.defaultOrganization !== undefined) {
      writer.uint32(42).string(message.defaultOrganization);
    }
    for (const v of message.requestedOrganizations) {
      writer.uint32(50).string(v!);
    }
    if (message.currentOrganization !== undefined) {
      writer.uint32(66).string(message.currentOrganization);
    }
    if (message.currentOrganizationPermissions !== undefined) {
      Filter_OrganizationPermissions.encode(message.currentOrganizationPermissions, writer.uint32(74).fork()).ldelim();
    }
    if (message.userPermissions !== undefined) {
      Filter_UserPermissions.encode(message.userPermissions, writer.uint32(82).fork()).ldelim();
    }
    if (message.supportPermissions !== undefined) {
      Filter_SupportPermissions.encode(message.supportPermissions, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.roles) {
      writer.uint32(122).string(v!);
    }
    if (message.missionControlSettings !== "") {
      writer.uint32(90).string(message.missionControlSettings);
    }
    if (message.acceptedTac !== false) {
      writer.uint32(96).bool(message.acceptedTac);
    }
    if (message.userHash !== "") {
      writer.uint32(106).string(message.userHash);
    }
    if (message.passwordResetRecommended !== false) {
      writer.uint32(128).bool(message.passwordResetRecommended);
    }
    if (message.passwordResetRecommendedReason !== undefined) {
      writer.uint32(138).string(message.passwordResetRecommendedReason);
    }
    if (message.activeRoleOverride !== false) {
      writer.uint32(144).bool(message.activeRoleOverride);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrentUser {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrentUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.email = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.organizations.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.defaultOrganization = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.requestedOrganizations.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.currentOrganization = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currentOrganizationPermissions = Filter_OrganizationPermissions.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.userPermissions = Filter_UserPermissions.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.supportPermissions = Filter_SupportPermissions.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.roles.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.missionControlSettings = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.acceptedTac = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.userHash = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.passwordResetRecommended = reader.bool();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.passwordResetRecommendedReason = reader.string();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.activeRoleOverride = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CurrentUser>): CurrentUser {
    return CurrentUser.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CurrentUser>): CurrentUser {
    const message = createBaseCurrentUser();
    message.id = object.id ?? "";
    message.email = object.email ?? undefined;
    message.name = object.name ?? undefined;
    message.organizations = object.organizations?.map((e) => e) || [];
    message.defaultOrganization = object.defaultOrganization ?? undefined;
    message.requestedOrganizations = object.requestedOrganizations?.map((e) => e) || [];
    message.currentOrganization = object.currentOrganization ?? undefined;
    message.currentOrganizationPermissions =
      (object.currentOrganizationPermissions !== undefined && object.currentOrganizationPermissions !== null)
        ? Filter_OrganizationPermissions.fromPartial(object.currentOrganizationPermissions)
        : undefined;
    message.userPermissions = (object.userPermissions !== undefined && object.userPermissions !== null)
      ? Filter_UserPermissions.fromPartial(object.userPermissions)
      : undefined;
    message.supportPermissions = (object.supportPermissions !== undefined && object.supportPermissions !== null)
      ? Filter_SupportPermissions.fromPartial(object.supportPermissions)
      : undefined;
    message.roles = object.roles?.map((e) => e) || [];
    message.missionControlSettings = object.missionControlSettings ?? "";
    message.acceptedTac = object.acceptedTac ?? false;
    message.userHash = object.userHash ?? "";
    message.passwordResetRecommended = object.passwordResetRecommended ?? false;
    message.passwordResetRecommendedReason = object.passwordResetRecommendedReason ?? undefined;
    message.activeRoleOverride = object.activeRoleOverride ?? false;
    return message;
  },
};

function createBaseCurrentUserRequest(): CurrentUserRequest {
  return { organization: undefined };
}

export const CurrentUserRequest = {
  encode(message: CurrentUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CurrentUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCurrentUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CurrentUserRequest>): CurrentUserRequest {
    return CurrentUserRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CurrentUserRequest>): CurrentUserRequest {
    const message = createBaseCurrentUserRequest();
    message.organization = object.organization ?? undefined;
    return message;
  },
};

function createBaseRequestAccessRequest(): RequestAccessRequest {
  return { organization: "" };
}

export const RequestAccessRequest = {
  encode(message: RequestAccessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestAccessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestAccessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RequestAccessRequest>): RequestAccessRequest {
    return RequestAccessRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RequestAccessRequest>): RequestAccessRequest {
    const message = createBaseRequestAccessRequest();
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseRequestAccessRequest_Response(): RequestAccessRequest_Response {
  return {};
}

export const RequestAccessRequest_Response = {
  encode(_: RequestAccessRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestAccessRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestAccessRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RequestAccessRequest_Response>): RequestAccessRequest_Response {
    return RequestAccessRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<RequestAccessRequest_Response>): RequestAccessRequest_Response {
    const message = createBaseRequestAccessRequest_Response();
    return message;
  },
};

function createBaseCancelAccessRequestRequest(): CancelAccessRequestRequest {
  return { organization: "" };
}

export const CancelAccessRequestRequest = {
  encode(message: CancelAccessRequestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelAccessRequestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelAccessRequestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CancelAccessRequestRequest>): CancelAccessRequestRequest {
    return CancelAccessRequestRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CancelAccessRequestRequest>): CancelAccessRequestRequest {
    const message = createBaseCancelAccessRequestRequest();
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseCancelAccessRequestRequest_Response(): CancelAccessRequestRequest_Response {
  return {};
}

export const CancelAccessRequestRequest_Response = {
  encode(_: CancelAccessRequestRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelAccessRequestRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelAccessRequestRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CancelAccessRequestRequest_Response>): CancelAccessRequestRequest_Response {
    return CancelAccessRequestRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<CancelAccessRequestRequest_Response>): CancelAccessRequestRequest_Response {
    const message = createBaseCancelAccessRequestRequest_Response();
    return message;
  },
};

function createBaseListOrganizationInvitesRequest(): ListOrganizationInvitesRequest {
  return { search: undefined };
}

export const ListOrganizationInvitesRequest = {
  encode(message: ListOrganizationInvitesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOrganizationInvitesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganizationInvitesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListOrganizationInvitesRequest>): ListOrganizationInvitesRequest {
    return ListOrganizationInvitesRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOrganizationInvitesRequest>): ListOrganizationInvitesRequest {
    const message = createBaseListOrganizationInvitesRequest();
    message.search = object.search ?? undefined;
    return message;
  },
};

function createBaseListOrganizationInvitesRequest_Invite(): ListOrganizationInvitesRequest_Invite {
  return { organization: "", displayName: "", invitedAt: undefined };
}

export const ListOrganizationInvitesRequest_Invite = {
  encode(message: ListOrganizationInvitesRequest_Invite, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.invitedAt !== undefined) {
      Timestamp.encode(message.invitedAt, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOrganizationInvitesRequest_Invite {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganizationInvitesRequest_Invite();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.invitedAt = Timestamp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListOrganizationInvitesRequest_Invite>): ListOrganizationInvitesRequest_Invite {
    return ListOrganizationInvitesRequest_Invite.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOrganizationInvitesRequest_Invite>): ListOrganizationInvitesRequest_Invite {
    const message = createBaseListOrganizationInvitesRequest_Invite();
    message.organization = object.organization ?? "";
    message.displayName = object.displayName ?? "";
    message.invitedAt = (object.invitedAt !== undefined && object.invitedAt !== null)
      ? Timestamp.fromPartial(object.invitedAt)
      : undefined;
    return message;
  },
};

function createBaseListOrganizationInvitesRequest_Response(): ListOrganizationInvitesRequest_Response {
  return { organizations: [] };
}

export const ListOrganizationInvitesRequest_Response = {
  encode(message: ListOrganizationInvitesRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.organizations) {
      ListOrganizationInvitesRequest_Invite.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListOrganizationInvitesRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListOrganizationInvitesRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizations.push(ListOrganizationInvitesRequest_Invite.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListOrganizationInvitesRequest_Response>): ListOrganizationInvitesRequest_Response {
    return ListOrganizationInvitesRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListOrganizationInvitesRequest_Response>): ListOrganizationInvitesRequest_Response {
    const message = createBaseListOrganizationInvitesRequest_Response();
    message.organizations = object.organizations?.map((e) => ListOrganizationInvitesRequest_Invite.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseAcceptOrganizationInviteRequest(): AcceptOrganizationInviteRequest {
  return { organization: "", defaultOrganization: undefined };
}

export const AcceptOrganizationInviteRequest = {
  encode(message: AcceptOrganizationInviteRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.defaultOrganization !== undefined) {
      writer.uint32(16).bool(message.defaultOrganization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptOrganizationInviteRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptOrganizationInviteRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.defaultOrganization = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AcceptOrganizationInviteRequest>): AcceptOrganizationInviteRequest {
    return AcceptOrganizationInviteRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AcceptOrganizationInviteRequest>): AcceptOrganizationInviteRequest {
    const message = createBaseAcceptOrganizationInviteRequest();
    message.organization = object.organization ?? "";
    message.defaultOrganization = object.defaultOrganization ?? undefined;
    return message;
  },
};

function createBaseAcceptOrganizationInviteRequest_Response(): AcceptOrganizationInviteRequest_Response {
  return {};
}

export const AcceptOrganizationInviteRequest_Response = {
  encode(_: AcceptOrganizationInviteRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptOrganizationInviteRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptOrganizationInviteRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AcceptOrganizationInviteRequest_Response>): AcceptOrganizationInviteRequest_Response {
    return AcceptOrganizationInviteRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AcceptOrganizationInviteRequest_Response>): AcceptOrganizationInviteRequest_Response {
    const message = createBaseAcceptOrganizationInviteRequest_Response();
    return message;
  },
};

function createBaseDeleteCurrentUserRequest(): DeleteCurrentUserRequest {
  return {};
}

export const DeleteCurrentUserRequest = {
  encode(_: DeleteCurrentUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCurrentUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCurrentUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteCurrentUserRequest>): DeleteCurrentUserRequest {
    return DeleteCurrentUserRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteCurrentUserRequest>): DeleteCurrentUserRequest {
    const message = createBaseDeleteCurrentUserRequest();
    return message;
  },
};

function createBaseDeleteCurrentUserRequest_Response(): DeleteCurrentUserRequest_Response {
  return {};
}

export const DeleteCurrentUserRequest_Response = {
  encode(_: DeleteCurrentUserRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCurrentUserRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCurrentUserRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteCurrentUserRequest_Response>): DeleteCurrentUserRequest_Response {
    return DeleteCurrentUserRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<DeleteCurrentUserRequest_Response>): DeleteCurrentUserRequest_Response {
    const message = createBaseDeleteCurrentUserRequest_Response();
    return message;
  },
};

function createBaseUpdateDefaultOrganizationRequest(): UpdateDefaultOrganizationRequest {
  return { organization: "" };
}

export const UpdateDefaultOrganizationRequest = {
  encode(message: UpdateDefaultOrganizationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDefaultOrganizationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDefaultOrganizationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDefaultOrganizationRequest>): UpdateDefaultOrganizationRequest {
    return UpdateDefaultOrganizationRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateDefaultOrganizationRequest>): UpdateDefaultOrganizationRequest {
    const message = createBaseUpdateDefaultOrganizationRequest();
    message.organization = object.organization ?? "";
    return message;
  },
};

function createBaseUpdateDefaultOrganizationRequest_Response(): UpdateDefaultOrganizationRequest_Response {
  return { defaultOrganization: "" };
}

export const UpdateDefaultOrganizationRequest_Response = {
  encode(message: UpdateDefaultOrganizationRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.defaultOrganization !== "") {
      writer.uint32(10).string(message.defaultOrganization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDefaultOrganizationRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDefaultOrganizationRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.defaultOrganization = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateDefaultOrganizationRequest_Response>): UpdateDefaultOrganizationRequest_Response {
    return UpdateDefaultOrganizationRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateDefaultOrganizationRequest_Response>,
  ): UpdateDefaultOrganizationRequest_Response {
    const message = createBaseUpdateDefaultOrganizationRequest_Response();
    message.defaultOrganization = object.defaultOrganization ?? "";
    return message;
  },
};

function createBaseUpdateMissionControlSettingsRequest(): UpdateMissionControlSettingsRequest {
  return { update: "" };
}

export const UpdateMissionControlSettingsRequest = {
  encode(message: UpdateMissionControlSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.update !== "") {
      writer.uint32(10).string(message.update);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMissionControlSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMissionControlSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.update = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateMissionControlSettingsRequest>): UpdateMissionControlSettingsRequest {
    return UpdateMissionControlSettingsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateMissionControlSettingsRequest>): UpdateMissionControlSettingsRequest {
    const message = createBaseUpdateMissionControlSettingsRequest();
    message.update = object.update ?? "";
    return message;
  },
};

function createBaseUpdateMissionControlSettingsRequest_Response(): UpdateMissionControlSettingsRequest_Response {
  return { missionControlSettings: "" };
}

export const UpdateMissionControlSettingsRequest_Response = {
  encode(message: UpdateMissionControlSettingsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.missionControlSettings !== "") {
      writer.uint32(10).string(message.missionControlSettings);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMissionControlSettingsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMissionControlSettingsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.missionControlSettings = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<UpdateMissionControlSettingsRequest_Response>,
  ): UpdateMissionControlSettingsRequest_Response {
    return UpdateMissionControlSettingsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<UpdateMissionControlSettingsRequest_Response>,
  ): UpdateMissionControlSettingsRequest_Response {
    const message = createBaseUpdateMissionControlSettingsRequest_Response();
    message.missionControlSettings = object.missionControlSettings ?? "";
    return message;
  },
};

function createBaseAcceptTermsAndConditionsRequest(): AcceptTermsAndConditionsRequest {
  return { acceptTac: false };
}

export const AcceptTermsAndConditionsRequest = {
  encode(message: AcceptTermsAndConditionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.acceptTac !== false) {
      writer.uint32(8).bool(message.acceptTac);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptTermsAndConditionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptTermsAndConditionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.acceptTac = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AcceptTermsAndConditionsRequest>): AcceptTermsAndConditionsRequest {
    return AcceptTermsAndConditionsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AcceptTermsAndConditionsRequest>): AcceptTermsAndConditionsRequest {
    const message = createBaseAcceptTermsAndConditionsRequest();
    message.acceptTac = object.acceptTac ?? false;
    return message;
  },
};

function createBaseAcceptTermsAndConditionsRequest_Response(): AcceptTermsAndConditionsRequest_Response {
  return {};
}

export const AcceptTermsAndConditionsRequest_Response = {
  encode(_: AcceptTermsAndConditionsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptTermsAndConditionsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptTermsAndConditionsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AcceptTermsAndConditionsRequest_Response>): AcceptTermsAndConditionsRequest_Response {
    return AcceptTermsAndConditionsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<AcceptTermsAndConditionsRequest_Response>): AcceptTermsAndConditionsRequest_Response {
    const message = createBaseAcceptTermsAndConditionsRequest_Response();
    return message;
  },
};

function createBaseLogPasswordUpdatedRequest(): LogPasswordUpdatedRequest {
  return {};
}

export const LogPasswordUpdatedRequest = {
  encode(_: LogPasswordUpdatedRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogPasswordUpdatedRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogPasswordUpdatedRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LogPasswordUpdatedRequest>): LogPasswordUpdatedRequest {
    return LogPasswordUpdatedRequest.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<LogPasswordUpdatedRequest>): LogPasswordUpdatedRequest {
    const message = createBaseLogPasswordUpdatedRequest();
    return message;
  },
};

function createBaseLogPasswordUpdatedRequest_Response(): LogPasswordUpdatedRequest_Response {
  return {};
}

export const LogPasswordUpdatedRequest_Response = {
  encode(_: LogPasswordUpdatedRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogPasswordUpdatedRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogPasswordUpdatedRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LogPasswordUpdatedRequest_Response>): LogPasswordUpdatedRequest_Response {
    return LogPasswordUpdatedRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<LogPasswordUpdatedRequest_Response>): LogPasswordUpdatedRequest_Response {
    const message = createBaseLogPasswordUpdatedRequest_Response();
    return message;
  },
};

function createBaseAccessibleOrganizationsRequest(): AccessibleOrganizationsRequest {
  return { search: undefined, memberOnly: undefined, defaultOnly: undefined, pagination: undefined };
}

export const AccessibleOrganizationsRequest = {
  encode(message: AccessibleOrganizationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    if (message.memberOnly !== undefined) {
      writer.uint32(24).bool(message.memberOnly);
    }
    if (message.defaultOnly !== undefined) {
      writer.uint32(32).bool(message.defaultOnly);
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccessibleOrganizationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccessibleOrganizationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.memberOnly = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.defaultOnly = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AccessibleOrganizationsRequest>): AccessibleOrganizationsRequest {
    return AccessibleOrganizationsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AccessibleOrganizationsRequest>): AccessibleOrganizationsRequest {
    const message = createBaseAccessibleOrganizationsRequest();
    message.search = object.search ?? undefined;
    message.memberOnly = object.memberOnly ?? undefined;
    message.defaultOnly = object.defaultOnly ?? undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseAccessibleOrganizationsRequest_AccessibleOrganization(): AccessibleOrganizationsRequest_AccessibleOrganization {
  return { organization: "", displayName: "", contact: undefined, member: false, defaultOrganization: false };
}

export const AccessibleOrganizationsRequest_AccessibleOrganization = {
  encode(
    message: AccessibleOrganizationsRequest_AccessibleOrganization,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.organization !== "") {
      writer.uint32(10).string(message.organization);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.contact !== undefined) {
      Organization_Contact.encode(message.contact, writer.uint32(42).fork()).ldelim();
    }
    if (message.member !== false) {
      writer.uint32(24).bool(message.member);
    }
    if (message.defaultOrganization !== false) {
      writer.uint32(32).bool(message.defaultOrganization);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccessibleOrganizationsRequest_AccessibleOrganization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccessibleOrganizationsRequest_AccessibleOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contact = Organization_Contact.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.member = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.defaultOrganization = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<AccessibleOrganizationsRequest_AccessibleOrganization>,
  ): AccessibleOrganizationsRequest_AccessibleOrganization {
    return AccessibleOrganizationsRequest_AccessibleOrganization.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<AccessibleOrganizationsRequest_AccessibleOrganization>,
  ): AccessibleOrganizationsRequest_AccessibleOrganization {
    const message = createBaseAccessibleOrganizationsRequest_AccessibleOrganization();
    message.organization = object.organization ?? "";
    message.displayName = object.displayName ?? "";
    message.contact = (object.contact !== undefined && object.contact !== null)
      ? Organization_Contact.fromPartial(object.contact)
      : undefined;
    message.member = object.member ?? false;
    message.defaultOrganization = object.defaultOrganization ?? false;
    return message;
  },
};

function createBaseAccessibleOrganizationsRequest_Response(): AccessibleOrganizationsRequest_Response {
  return { organizations: [], pagination: undefined };
}

export const AccessibleOrganizationsRequest_Response = {
  encode(message: AccessibleOrganizationsRequest_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.organizations) {
      AccessibleOrganizationsRequest_AccessibleOrganization.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AccessibleOrganizationsRequest_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAccessibleOrganizationsRequest_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.organizations.push(
            AccessibleOrganizationsRequest_AccessibleOrganization.decode(reader, reader.uint32()),
          );
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AccessibleOrganizationsRequest_Response>): AccessibleOrganizationsRequest_Response {
    return AccessibleOrganizationsRequest_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AccessibleOrganizationsRequest_Response>): AccessibleOrganizationsRequest_Response {
    const message = createBaseAccessibleOrganizationsRequest_Response();
    message.organizations =
      object.organizations?.map((e) => AccessibleOrganizationsRequest_AccessibleOrganization.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseOverrideRoles(): OverrideRoles {
  return {};
}

export const OverrideRoles = {
  encode(_: OverrideRoles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverrideRoles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverrideRoles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OverrideRoles>): OverrideRoles {
    return OverrideRoles.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<OverrideRoles>): OverrideRoles {
    const message = createBaseOverrideRoles();
    return message;
  },
};

function createBaseOverrideRoles_Request(): OverrideRoles_Request {
  return { organization: undefined, selection: undefined };
}

export const OverrideRoles_Request = {
  encode(message: OverrideRoles_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      RoleSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverrideRoles_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverrideRoles_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = RoleSelection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OverrideRoles_Request>): OverrideRoles_Request {
    return OverrideRoles_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OverrideRoles_Request>): OverrideRoles_Request {
    const message = createBaseOverrideRoles_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? RoleSelection.fromPartial(object.selection)
      : undefined;
    return message;
  },
};

function createBaseOverrideRoles_Response(): OverrideRoles_Response {
  return { currentUser: undefined, request: undefined };
}

export const OverrideRoles_Response = {
  encode(message: OverrideRoles_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currentUser !== undefined) {
      CurrentUser.encode(message.currentUser, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      OverrideRoles_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverrideRoles_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverrideRoles_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currentUser = CurrentUser.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = OverrideRoles_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OverrideRoles_Response>): OverrideRoles_Response {
    return OverrideRoles_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OverrideRoles_Response>): OverrideRoles_Response {
    const message = createBaseOverrideRoles_Response();
    message.currentUser = (object.currentUser !== undefined && object.currentUser !== null)
      ? CurrentUser.fromPartial(object.currentUser)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? OverrideRoles_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseOverrideRoles_Restore(): OverrideRoles_Restore {
  return {};
}

export const OverrideRoles_Restore = {
  encode(_: OverrideRoles_Restore, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverrideRoles_Restore {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverrideRoles_Restore();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OverrideRoles_Restore>): OverrideRoles_Restore {
    return OverrideRoles_Restore.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<OverrideRoles_Restore>): OverrideRoles_Restore {
    const message = createBaseOverrideRoles_Restore();
    return message;
  },
};

function createBaseOverrideRoles_Restore_Request(): OverrideRoles_Restore_Request {
  return { organization: [] };
}

export const OverrideRoles_Restore_Request = {
  encode(message: OverrideRoles_Restore_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.organization) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverrideRoles_Restore_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverrideRoles_Restore_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OverrideRoles_Restore_Request>): OverrideRoles_Restore_Request {
    return OverrideRoles_Restore_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OverrideRoles_Restore_Request>): OverrideRoles_Restore_Request {
    const message = createBaseOverrideRoles_Restore_Request();
    message.organization = object.organization?.map((e) => e) || [];
    return message;
  },
};

function createBaseOverrideRoles_Restore_Response(): OverrideRoles_Restore_Response {
  return { currentUser: undefined, request: undefined };
}

export const OverrideRoles_Restore_Response = {
  encode(message: OverrideRoles_Restore_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currentUser !== undefined) {
      CurrentUser.encode(message.currentUser, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      OverrideRoles_Restore_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OverrideRoles_Restore_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOverrideRoles_Restore_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currentUser = CurrentUser.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = OverrideRoles_Restore_Request.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<OverrideRoles_Restore_Response>): OverrideRoles_Restore_Response {
    return OverrideRoles_Restore_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OverrideRoles_Restore_Response>): OverrideRoles_Restore_Response {
    const message = createBaseOverrideRoles_Restore_Response();
    message.currentUser = (object.currentUser !== undefined && object.currentUser !== null)
      ? CurrentUser.fromPartial(object.currentUser)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? OverrideRoles_Restore_Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

/**
 * Calls related to the current user. Typically, a newly created user only has access to these calls, all
 * others require an organization to be linked.
 */
export type CurrentUserServiceDefinition = typeof CurrentUserServiceDefinition;
export const CurrentUserServiceDefinition = {
  name: "CurrentUserService",
  fullName: "hiber.user.CurrentUserService",
  methods: {
    currentUser: {
      name: "CurrentUser",
      requestType: CurrentUserRequest,
      requestStream: false,
      responseType: CurrentUser,
      responseStream: false,
      options: {},
    },
    deleteCurrentUser: {
      name: "DeleteCurrentUser",
      requestType: DeleteCurrentUserRequest,
      requestStream: false,
      responseType: DeleteCurrentUserRequest_Response,
      responseStream: false,
      options: {},
    },
    requestAccess: {
      name: "RequestAccess",
      requestType: RequestAccessRequest,
      requestStream: false,
      responseType: RequestAccessRequest_Response,
      responseStream: false,
      options: {},
    },
    cancelAccessRequest: {
      name: "CancelAccessRequest",
      requestType: CancelAccessRequestRequest,
      requestStream: false,
      responseType: CancelAccessRequestRequest_Response,
      responseStream: false,
      options: {},
    },
    listOrganizationInvites: {
      name: "ListOrganizationInvites",
      requestType: ListOrganizationInvitesRequest,
      requestStream: false,
      responseType: ListOrganizationInvitesRequest_Response,
      responseStream: false,
      options: {},
    },
    acceptOrganizationInvite: {
      name: "AcceptOrganizationInvite",
      requestType: AcceptOrganizationInviteRequest,
      requestStream: false,
      responseType: AcceptOrganizationInviteRequest_Response,
      responseStream: false,
      options: {},
    },
    accessibleOrganizations: {
      name: "AccessibleOrganizations",
      requestType: AccessibleOrganizationsRequest,
      requestStream: false,
      responseType: AccessibleOrganizationsRequest_Response,
      responseStream: false,
      options: {},
    },
    updateDefaultOrganization: {
      name: "UpdateDefaultOrganization",
      requestType: UpdateDefaultOrganizationRequest,
      requestStream: false,
      responseType: UpdateDefaultOrganizationRequest_Response,
      responseStream: false,
      options: {},
    },
    updateMissionControlSettings: {
      name: "UpdateMissionControlSettings",
      requestType: UpdateMissionControlSettingsRequest,
      requestStream: false,
      responseType: UpdateMissionControlSettingsRequest_Response,
      responseStream: false,
      options: {},
    },
    acceptTermsAndConditions: {
      name: "AcceptTermsAndConditions",
      requestType: AcceptTermsAndConditionsRequest,
      requestStream: false,
      responseType: AcceptTermsAndConditionsRequest_Response,
      responseStream: false,
      options: {},
    },
    logPasswordUpdated: {
      name: "LogPasswordUpdated",
      requestType: LogPasswordUpdatedRequest,
      requestStream: false,
      responseType: LogPasswordUpdatedRequest_Response,
      responseStream: false,
      options: {},
    },
    overrideRoles: {
      name: "OverrideRoles",
      requestType: OverrideRoles_Request,
      requestStream: false,
      responseType: OverrideRoles_Response,
      responseStream: false,
      options: {},
    },
    restoreRoles: {
      name: "RestoreRoles",
      requestType: OverrideRoles_Restore_Request,
      requestStream: false,
      responseType: OverrideRoles_Restore_Response,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
