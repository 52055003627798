// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: file.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { BytesOrHex } from "./base";

export const protobufPackage = "hiber.file";

/**
 * A file in your organization, with its mime-type and name.
 * It can represent any file of any type.
 *
 * Specific API calls may pur restrictions on the name or size of the file.
 *
 * To avoid sending large amounts of binary data, File does not typically contain the file's content.
 * The content can be fetched at a url, or using the FileService.Get rpc.
 * For specific use cases, the data field might be set with binary data, avoiding the need for another lookup.
 *
 * The file name should be interpreted as-is.
 * No hierarchical information is stored in the name, nor should you look at the "extension" to know its media-type.
 * It might not even have a file extension.
 * The file name may contain characters that cannot be a valid file name on certain systems.
 *
 * When showing this as an image in a browser, one can make use of a `data` URI.
 * The client must convert the bytes to base64 and can then construct a data URI like this
 *
 *     data:<media-type>;base64,<base64-encoded-bytes>
 *
 * Other type clients should be able to sort-of-directly set the data bytes as the source for an image.
 *
 * (Technical note: the indices are structured for binary backwards compatibility with the now-removed NamedFile.)
 */
export interface File {
  /** This file's content can be fetched using the FileService, with this file identifier. */
  identifier: string;
  content?:
    | { $case: "data"; data: BytesOrHex }
    | { $case: "dataFormattedHex"; dataFormattedHex: string }
    | { $case: "dataFormattedBase64"; dataFormattedBase64: string }
    | { $case: "dataUri"; dataUri: string }
    | { $case: "dataRaw"; dataRaw: Uint8Array }
    | { $case: "fileService"; fileService: boolean }
    | { $case: "url"; url: string }
    | undefined;
  /** The media-type of the file, as defined by RFC 6838 or its extensions */
  mediaType: string;
  /** A semantic name for this file. */
  name: string;
  /**
   * A link to file in the Hiber Rest API.
   * Note: the Hiber Rest API requires a token, for authenticated access to your files.
   * If the content oneof of this file is a url, this link will return a HTTP 301 code to redirect to that url.
   */
  link: string;
}

/** Selection to use when listing files in your organization. */
export interface FileSelection {
  /** Search files in your organization by (partial) match on name, media type and (if present) url. */
  search?:
    | string
    | undefined;
  /** List files in your organization with a specific identifier. */
  identifiers: string[];
}

function createBaseFile(): File {
  return { identifier: "", content: undefined, mediaType: "", name: "", link: "" };
}

export const File = {
  encode(message: File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(34).string(message.identifier);
    }
    switch (message.content?.$case) {
      case "data":
        BytesOrHex.encode(message.content.data, writer.uint32(10).fork()).ldelim();
        break;
      case "dataFormattedHex":
        writer.uint32(66).string(message.content.dataFormattedHex);
        break;
      case "dataFormattedBase64":
        writer.uint32(74).string(message.content.dataFormattedBase64);
        break;
      case "dataUri":
        writer.uint32(82).string(message.content.dataUri);
        break;
      case "dataRaw":
        writer.uint32(90).bytes(message.content.dataRaw);
        break;
      case "fileService":
        writer.uint32(40).bool(message.content.fileService);
        break;
      case "url":
        writer.uint32(50).string(message.content.url);
        break;
    }
    if (message.mediaType !== "") {
      writer.uint32(18).string(message.mediaType);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.link !== "") {
      writer.uint32(58).string(message.link);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): File {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = { $case: "data", data: BytesOrHex.decode(reader, reader.uint32()) };
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.content = { $case: "dataFormattedHex", dataFormattedHex: reader.string() };
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.content = { $case: "dataFormattedBase64", dataFormattedBase64: reader.string() };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.content = { $case: "dataUri", dataUri: reader.string() };
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.content = { $case: "dataRaw", dataRaw: reader.bytes() };
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.content = { $case: "fileService", fileService: reader.bool() };
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.content = { $case: "url", url: reader.string() };
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mediaType = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.link = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<File>): File {
    return File.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<File>): File {
    const message = createBaseFile();
    message.identifier = object.identifier ?? "";
    if (object.content?.$case === "data" && object.content?.data !== undefined && object.content?.data !== null) {
      message.content = { $case: "data", data: BytesOrHex.fromPartial(object.content.data) };
    }
    if (
      object.content?.$case === "dataFormattedHex" &&
      object.content?.dataFormattedHex !== undefined &&
      object.content?.dataFormattedHex !== null
    ) {
      message.content = { $case: "dataFormattedHex", dataFormattedHex: object.content.dataFormattedHex };
    }
    if (
      object.content?.$case === "dataFormattedBase64" &&
      object.content?.dataFormattedBase64 !== undefined &&
      object.content?.dataFormattedBase64 !== null
    ) {
      message.content = { $case: "dataFormattedBase64", dataFormattedBase64: object.content.dataFormattedBase64 };
    }
    if (
      object.content?.$case === "dataUri" && object.content?.dataUri !== undefined && object.content?.dataUri !== null
    ) {
      message.content = { $case: "dataUri", dataUri: object.content.dataUri };
    }
    if (
      object.content?.$case === "dataRaw" && object.content?.dataRaw !== undefined && object.content?.dataRaw !== null
    ) {
      message.content = { $case: "dataRaw", dataRaw: object.content.dataRaw };
    }
    if (
      object.content?.$case === "fileService" &&
      object.content?.fileService !== undefined &&
      object.content?.fileService !== null
    ) {
      message.content = { $case: "fileService", fileService: object.content.fileService };
    }
    if (object.content?.$case === "url" && object.content?.url !== undefined && object.content?.url !== null) {
      message.content = { $case: "url", url: object.content.url };
    }
    message.mediaType = object.mediaType ?? "";
    message.name = object.name ?? "";
    message.link = object.link ?? "";
    return message;
  },
};

function createBaseFileSelection(): FileSelection {
  return { search: undefined, identifiers: [] };
}

export const FileSelection = {
  encode(message: FileSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    for (const v of message.identifiers) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FileSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.identifiers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<FileSelection>): FileSelection {
    return FileSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<FileSelection>): FileSelection {
    const message = createBaseFileSelection();
    message.search = object.search ?? undefined;
    message.identifiers = object.identifiers?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
